import { handleResponse, authHeader } from '../Helpers';

export const ItemService = {
    addNewItem, editItem, deleteItem, completeItem
};

function addNewItem(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/item/add`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function editItem(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/item/edit`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function deleteItem(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/item/delete`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
function completeItem(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/item/complete`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
import React, { Component } from 'react';
import styled from "@emotion/styled";

import { colors } from "@atlaskit/theme";
import reorder, { reorderQuoteMap } from "./reorder";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./column";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { userService } from "../../Shared/Services/userService";

//const ParentContainer = styled.div`
//  /* height: ${({ height }) => height}; */
//  /* overflow-x: hidden;
//  overflow-y: auto; */
//`;

const Container = styled.div`
/*  background-color: ${colors.B100};*/
  /* min-height: 100vh; */
  /* like display:flex but will allow bleeding over the window width */
  /* min-width: 100vw; */
  display: inline-flex;
/*margin-top: 65px;*/
width:100%;
/*overflow-x:auto;*/
`;
class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userLists: [],
            modelAddList: false,
            fields: {
                Name: ''
            },
            errors: {},
            loading: false,
            error: ""
        };
        //var handleToUpdate = this.handleToUpdate.bind(this);
    }
    componentDidMount() {
        this.getuserList();
        setInterval(() => {
            this.getuserList();
        }, 1800000);
    }

    getuserList = () => {
        userService.getUserLists().then((resp) => {
            if (resp.status === true)
                this.setState({ userLists: resp.data })
        });
    }

    toggleAddListModel = () => {
        this.setState({ modelAddList: !this.state.modelAddList });
    };

    handleToUpdate(userLists) {
        this.setState({ userLists: userLists })
    }

    ReorderList() {
        userService.reorderUserList(this.state.userLists)
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["Name"]) {
            formIsValid = false;
            errors["Name"] = "Please provide list name";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleItemChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    addListSubmit() {
        let fields = this.state.fields;
        if (this.handleValidation()) {
            this.setState({ loading: true });
            userService.addNewList({ Name: fields["Name"] }).then((resp) => {
                //console.log(resp)
                if (resp.status === false) {
                    this.setState({ error: resp.message });
                }
                else {
                    this.setState({
                        userLists: resp.data,
                        fields: {
                            Name: ''
                        },
                        modelAddList: false
                    })
                }
                this.setState({ loading: false });
            });
        } else {
        }
    }

    onDragEnd = result => {
        //if (result.combine) {
        //    if (result.type === "COLUMN") {
        //        const shallow = [...this.state.userLists];
        //        shallow.splice(result.source.index, 1);
        //        this.setState({ userLists: shallow });
        //        return;
        //    }

        //    const column = this.state.columns[result.source.droppableId];
        //    const withQuoteRemoved = [...column];
        //    withQuoteRemoved.splice(result.source.index, 1);
        //    const columns = {
        //        ...this.state.columns,
        //        [result.source.droppableId]: withQuoteRemoved
        //    };
        //    this.setState({ columns });
        //    return;
        //}


        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source = result.source;
        const destination = result.destination;

        // did not move anywhere - can bail early
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        // reordering column
        if (result.type === "COLUMN") {
            const userLists = reorder(
                this.state.userLists,
                source.index,
                destination.index
            );

            this.setState({
                userLists
            }, () => {
                this.ReorderList();
            });


            return;
        }
        const data = reorderQuoteMap({
            itemsMap: this.state.userLists,
            source,
            destination,
            draggableId: result.draggableId
        });

        this.setState({
            userLists: data
        });
    };
    render() {
        let lists = this.state.userLists;
        let { loading, error } = this.state;
        const board = (
            <div>
                <div>
                    <button className="btn btn-dark ml-3 mb-2" type="button" onClick={() => this.toggleAddListModel()}>Add new list</button>
                </div>
                <div className="dinFlex">
                    <Droppable
                        droppableId="board"
                        type="COLUMN"
                        direction="horizontal">
                        {provided => (
                            <Container ref={provided.innerRef} {...provided.droppableProps}>
                                {lists.length > 0 && lists.map((item, index) => (
                                    <Column
                                        handleToUpdate={this.handleToUpdate.bind(this)}
                                        key={item.listId}
                                        index={index}
                                        title={item.name}
                                        list={item}
                                        items={item.items}
                                        isScrollable={true}
                                        isCombineEnabled={false}
                                    />

                                ))}
                                {provided.placeholder}
                            </Container>
                        )}
                    </Droppable>
                </div>
            </div>
        );

        return (
            <React.Fragment>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {
                        lists.length === 0 ? <div>
                            <button className="btn btn-dark ml-3 mb-2" type="button" onClick={() => this.toggleAddListModel()}>Add new list</button>
                        </div> : board
                    }
                </DragDropContext>
                <div>
                    <Modal toggle={() => this.setState({ modelAddList: !this.state.modelAddList })} isOpen={this.state.modelAddList} >
                        <ModalHeader>Add new list</ModalHeader>
                        <ModalBody>
                            {error !== "" && <div className="alert alert-danger" role="alert">
                                {error}
                            </div>}
                            <div className="form-white mb-2">
                                <label className="form-label" htmlFor="Name">Name</label>
                                <input type="text" id="Name" name="Name" className="form-control form-control-lg"
                                    onChange={this.handleItemChange.bind(this, "Name")}
                                    value={this.state.fields["Name"]}
                                />
                                <span className="text-danger">{this.state.errors["Name"]}</span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {loading ?
                                <Button color="secondary" disabled >
                                    <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span></Button> :
                                <Button color="success" type="button" onClick={() => this.addListSubmit()}>Create List</Button>
                            }
                            <Button color="secondary" onClick={() => this.setState({ modelAddList: !this.state.modelAddList })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}
export default Dashboard;
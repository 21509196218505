import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { authService, userService } from '../Shared/Services';
import { history } from '../Shared/Helpers';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            pendingRequestCount: 0
        };
    }

    componentDidMount() {
        authService.pendingRequests.subscribe(x => {
            this.setState({ pendingRequestCount: x });
        });
        this.getRequestCount();
        this.initInterval();
    }

    initInterval =() => {
        setInterval(() => {
            this.getRequestCount()
        }, 300000);
    }
    getRequestCount =() => {
        userService.getPendingRequests().then((resp) => {
            if (resp.status === true) {
                authService.setPendingRequest(resp.data);
            }
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    logout() {
        authService.logout();
        history.push('/login');
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/dashboard">Focus Board</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/dashboard">Dashboard</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/users">Users</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/resetpassword">Reset password</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/requests">Requests
                                        {this.state.pendingRequestCount > 0 && <span className="badge badge-danger badgeStyle">{this.state.pendingRequestCount}</span>}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="#/" tag={Link} className="text-dark" onClick={this.logout}>Logout</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

import { BehaviorSubject } from 'rxjs';
import { handleResponse, authHeader } from '../Helpers';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const pendingRequests = new BehaviorSubject(0);

export const authService = {
    login,
    logout,
    resetpassword,
    currentUser: currentUserSubject.asObservable(),
    pendingRequests: pendingRequests.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    forgotpassword,
    resetpassworduser,
    getUserByToken,
    setPendingRequest
};

function login(Username, Password, IsRemember) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Username, Password, IsRemember })
    };

    return fetch(`/auth/login`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            let result = resp.response;
            if (result.status === true) {
                localStorage.setItem('currentUser', JSON.stringify(result.data));
                currentUserSubject.next(result.data);
            }
            return result;
        });
}

function logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function resetpassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/auth/resetpassword`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
function forgotpassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        //headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/auth/forgotpassword`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function resetpassworduser(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`/auth/resetpassworduser`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function getUserByToken(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`/auth/getuserbytoken`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
function setPendingRequest(count) {
    pendingRequests.next(count);
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { authService } from '../../Shared/Services';
class ResetPasswordUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                NewPassword: '',
                ConfirmPassword: '',
                UserId: 0
            },
            errors: {},
            token: "",
            loading: false,
            error: "",
            success:""
        };
    }

    componentDidMount() {
        const token = new URLSearchParams(window.location.search).get('token')
        this.setState({ token: token }, () => {
            this.loadUserByToken()
        })
    }

    loadUserByToken() {
        this.setState({ loading: true });
        authService.getUserByToken({ Token: this.state.token }).then((resp) => {
            if (resp.status === false) {
                this.setState({ error: resp.message });
            }
            else {
                this.setState({ fields: { UserId: resp.data } });
            }
            this.setState({ loading: false });
        });
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["NewPassword"]) {
            formIsValid = false;
            errors["NewPassword"] = "Please provide new password";
        }
        if (!fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = "Please provide confirm password";
        }

        else if (fields["NewPassword"] !== fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = "Password does not match";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    resetPasswordSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ loading: true });
            authService.resetpassworduser(this.state.fields).then((resp) => {
                if (resp.status === false) {
                    this.setState({error : resp.message})
                }
                else {
                    this.setState({success: resp.message })
                }
                this.setState({ loading: false });
            });
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    render() {
        let { error, success, loading } = this.state;
        return (
            <section className="">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                {loading ? (<p className="spinner-border spinner-border loaderM" role="status" aria-hidden="true"></p>) : (
                                    <div className="card-body p-md-5">
                                        {this.state.token != "" && this.state.fields["UserId"] > 0 ? (
                                            <div className="mb-md-2 mt-md-2 pb-0">

                                                <h2 className="fw-bold mb-2 text-center">Reset Password</h2>
                                                <p className="mb-3 text-center">Reset your password!</p>
                                                {error !== "" && <div className="alert alert-danger" role="alert">
                                                    {error}
                                                </div>}
                                                {success !== "" && <div className="alert alert-success" role="alert">
                                                    {success}
                                                </div>}
                                                <form name="loginForm" onSubmit={this.resetPasswordSubmit.bind(this)}>

                                                    <div className=" form-white mb-4">
                                                        <label className="form-label" htmlFor="NewPassword">New password</label>
                                                        <input type="password" id="NewPassword" name="NewPassword" className="form-control form-control-lg"
                                                            onChange={this.handleChange.bind(this, "NewPassword")}
                                                            value={this.state.fields["NewPassword"]}
                                                        />
                                                        <span className="text-danger">{this.state.errors["NewPassword"]}</span>
                                                    </div>

                                                    <div className=" form-white mb-4">
                                                        <label className="form-label" htmlFor="ConfirmPassword">Confirm new password</label>
                                                        <input type="password" id="ConfirmPassword" name="ConfirmPassword" className="form-control form-control-lg"
                                                            onChange={this.handleChange.bind(this, "ConfirmPassword")}
                                                            value={this.state.fields["ConfirmPassword"]}
                                                        />
                                                        <span className="text-danger">{this.state.errors["ConfirmPassword"]}</span>
                                                    </div>

                                                    {
                                                        loading ? <button className="btn btn-primary btn-lg px-5 w-100" disabled type="button">
                                                            <span className="spinner-border spinner-border" role="status"></span>
                                                        </button> :
                                                            <button className="btn btn-primary btn-lg px-5 w-100" type="submit">Reset password</button>
                                                    }
                                                </form>
                                            </div>
                                        ) : (
                                            <div className="mb-md-2 mt-md-2 pb-0">
                                                    Token is not valid go to <Link to="/login">Login</Link>
                                            </div>
                                        )
                                        }

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}
export default ResetPasswordUser;
import React, { Component } from 'react';
import { userService, authService } from '../../Shared/Services';
import Moment from 'moment';
class Requests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allRequests: [],
            loading: false,
            pendingRequestCount: 0
        };
    }
    componentDidMount() {
        authService.pendingRequests.subscribe(x => {
            this.setState({ pendingRequestCount: x })
            this.getAllRequests();
        });
    }

    getAllRequests = () => {
        this.setState({ loading: true })
        userService.getOwnerRequests().then((resp) => {
            if (resp.status === true)
                this.setState({ allRequests: resp.data, loading: false })
        });
    }

    acceptOwnerRequest = (requestId) => {
        if (window.confirm("Are you sure you want to accept this ownership request?")) {
            userService.respOwnerRequest({ RequestId: requestId, IsAccepted: true }).then((resp) => {
                if (resp.status === true)
                    this.setState({ allRequests: resp.data, loading: false })
                authService.setPendingRequest(this.state.pendingRequestCount - 1);
            });
        }
    }
    rejectOwnerRequest = (requestId) => {
        if (window.confirm("Are you sure you want to reject this ownership request?")) {
            userService.respOwnerRequest({ RequestId: requestId, IsAccepted: false }).then((resp) => {
                if (resp.status === true) {
                    this.setState({ allRequests: resp.data, loading: false })
                    authService.setPendingRequest(this.state.pendingRequestCount - 1);
                }
            });
        }
    }

    render() {
        let requests = this.state.allRequests;
        let loading = this.state.loading;
        return (
            <div className="container mt-2">
                <div className="row">
                    <div className="container">
                        <div className="col-12 card">
                            {
                                loading ? <p className="text-center pt-3">Loading...</p> :
                                    requests.length === 0 ? <p className="text-center pt-3">No requests available</p> :
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>By User</th>
                                                    <th>List</th>
                                                    <th>Created On</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {requests.map((item) => (
                                                    <tr key={item.requestId}>
                                                        <td>{item.byUser}</td>
                                                        <td>{item.listName}</td>
                                                        <td>{Moment(item.createdOn).format('MM/DD/YYYY')}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.status === "Pending" ? <>
                                                            <button className="btn btn-success btn-sm" onClick={() => this.acceptOwnerRequest(item.requestId)}>Accept</button>
                                                            <button className="btn ml-1 btn-sm btn-danger" onClick={() => this.rejectOwnerRequest(item.requestId)}>Reject</button>
                                                        </> : item.status}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Requests;
import React, { Component } from 'react';
import { authService } from '../../Shared/Services';
import { Link } from 'react-router-dom';
class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                Email:''
            },
            errors: {},
            success: "",
            error: "",
            loading:false
        };
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = "Please enter email address";
        }

        else if (typeof fields["Email"] !== "undefined") {
            let lastAtPos = fields["Email"].lastIndexOf("@");
            let lastDotPos = fields["Email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["Email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["Email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["Email"] = "Email address is not valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    forgotPasswordSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ loading: true, error: "", success:"" })
            authService.forgotpassword(this.state.fields).then((resp) => {
                if (resp.status === false) {
                    this.setState({ error: resp.message, loading:false})
                }
                else{
                    this.setState({fields: {
                        Email: ''
                    }, success: resp.message, loading :false
                    });
                }
            });
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    render() {
        let { error,success,loading} = this.state;
        return (
            <section className="">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body p-md-5">
                                    <div className="mb-md-2 mt-md-2 pb-0">

                                        <h2 className="fw-bold mb-2 text-center">Forgot Password</h2>
                                        <p className="mb-3 text-center">Forgot your password!</p>
                                        {error !== "" && <div className="alert alert-danger" role="alert">
                                            {error}
                                        </div>}
                                        {success !== "" && <div className="alert alert-success" role="alert">
                                            {success}
                                        </div>}
                                        <form name="loginForm" onSubmit={this.forgotPasswordSubmit.bind(this)}>
                                            <div className="form-white mb-4">
                                                <label className="form-label" htmlFor="Email">Email</label>
                                                <input type="text" id="Email" name="Email" className="form-control form-control-lg"
                                                    onChange={this.handleChange.bind(this, "Email")}
                                                    value={this.state.fields["Email"]}
                                                />
                                                <span className="text-danger">{this.state.errors["Email"]}</span>
                                            </div>
                                            <p className="small mb-2 pb-lg-2 text-center">
                                                    <Link to="/login">Login</Link>
                                                    <br />
                                                    <br />
                                                
                                                {
                                                    loading ? <button className="btn btn-primary btn-lg px-5 w-100" disabled type="button">
                                                        <span className="spinner-border spinner-border" role="status"></span>
                                                    </button> :
                                                        <button className="btn btn-primary btn-lg px-5 w-100" type="submit">Submit</button>
                                                }
                                            </p>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ForgotPassword;
import React, { Component } from 'react';
import  {userService}  from '../../Shared/Services';
//import {Link} from 'react-router-dom'
class AddUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                FirstName:'',
                LastName:'',
                Email:'',
                Password: '',
                CreateLists: null
            },
            errors: {},
            loading: false,
            error: "",
            success:""
        };
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (!fields["FirstName"]) {
            formIsValid = false;
            errors["FirstName"] = "Please provide your first name";
        }

        if (!fields["LastName"]) {
            formIsValid = false;
            errors["LastName"] = "Please provide new last name";
        }
        if (!fields["Email"]) {
            formIsValid = false;
            errors["Email"] = "Please provide email address";
        }

        else if (typeof fields["Email"] !== "undefined") {
            let lastAtPos = fields["Email"].lastIndexOf("@");
            let lastDotPos = fields["Email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["Email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["Email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["Email"] = "Email address is not valid";
            }
        }

         if(!fields["Password"]){
            formIsValid = false;
             errors["Password"] = "Please provide password";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    addUserSubmit(e) {
        this.setState({ success: "",error:"" });
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ loading: true });
            userService.addUser(this.state.fields).then((resp) => {

                if (resp.status === false) {
                    this.setState({ error: resp.message });
                }
                else{
                    this.setState({fields: {
                        FirstName:'',
                        LastName:'',
                        Email:'',
                        Password: '',
                        CreateLists: null
                    }
                    });
                    this.setState({ success: resp.message });
                }
                this.setState({ loading: false });
            });
        } else {
        }
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    render() {
        let loading = this.state.loading;
        let error = this.state.error;
        let success = this.state.success;
        return (
            <section className="">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body p-md-5">
                                    <div className="mb-md-2 mt-md-2 pb-0">
                                        <h2 className="fw-bold mb-2 text-center">Create new user</h2>
                                        {success !== "" && <div className="alert alert-success" role="alert">
                                            {success}
                                        </div>}
                                        {error !== "" && <div className="alert alert-danger" role="alert">
                                            {error}
                                        </div>}
                                        {/*<p className="text-white-50 mb-3 text-center">Add new User!</p>*/}
                                        <form name="loginForm" onSubmit={this.addUserSubmit.bind(this)}>
                                            <div className="form-white mb-4">
                                                <label className="form-label" htmlFor="FirstName">First name</label>
                                                <input type="text" id="FirstName" name="FirstName" className="form-control form-control-lg"
                                                    onChange={this.handleChange.bind(this, "FirstName")}
                                                    value={this.state.fields["FirstName"]}
                                                />
                                                <span className="text-danger">{this.state.errors["FirstName"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <label className="form-label" htmlFor="LastName">Last name</label>
                                                <input type="text" id="LastName" name="LastName" className="form-control form-control-lg"
                                                onChange={this.handleChange.bind(this, "LastName")}
                                                value={this.state.fields["LastName"]}
                                                />
                                                <span className="text-danger">{this.state.errors["LastName"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <label className="form-label" htmlFor="Email">Email</label>
                                                <input type="email" id="Email" name="Email" className="form-control form-control-lg"
                                                onChange={this.handleChange.bind(this, "Email")}
                                                value={this.state.fields["Email"]}
                                                />
                                                <span className="text-danger">{this.state.errors["Email"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <label className="form-label" htmlFor="Password">Password</label>
                                                <input type="password" id="Password" name="Password" className="form-control form-control-lg"
                                                onChange={this.handleChange.bind(this, "Password")}
                                                value={this.state.fields["Password"]}
                                                />
                                                <span className="text-danger">{this.state.errors["Password"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <input type="checkbox" id="CreateLists" name="CreateLists"
                                                    onChange={this.handleChange.bind(this, "CreateLists")}
                                                />
                                                <label className="form-label ml-2" htmlFor="CreateLists">Create lists</label>
                                            </div>

                                            <p className="small mb-2 pb-lg-2 text-center">
                                                {loading ?
                                                    <button className="btn btn-outline-light btn-lg px-5 w-100" disabled type="button">
                                                        <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span></button> :
                                                    <button className="btn btn-primary btn-lg px-5 w-100" type="submit">Create user</button>
                                                }
                                                
                                            </p>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default AddUser;
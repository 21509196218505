import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Layout } from './components/Layout';
import jwt_decode from "jwt-decode";

import Login from "./Pages/Auth/login";
import ResetPassword from "./Pages/Auth/resetpassword";
import ResetPasswordUser from "./Pages/Auth/resetpassworduser";
import ForgotPassword from "./Pages/Auth/forgotpassword";
import Dashboard from "./Pages/Dashboard/dashboard";
import Users from "./Pages/User/users";
import AddUser from "./Pages/User/adduser";
import Requests from "./Pages/Requests/requests";

import { authService } from './Shared/Services';
import { history } from './Shared/Helpers';
import './custom.css'

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            loading: true,
            isExpired: false
        };
        this.logout = this.logout.bind(this);
    }
    logout() {
        //this.setState({ isExpired: false }, () => {
            authService.logout();
            history.push('/login');
        //});

    }

    componentDidMount() {
        var decoded = null;
        authService.currentUser.subscribe(x => this.setState({ currentUser: x, loading: false }, () => {
            if (this.state.currentUser !== null) {
                decoded = jwt_decode(this.state.currentUser.token);
                if (Date.now() / 1000 >= decoded.exp) {
                    //this.setState({ isExpired: true });
                    this.logout();
                }
                if (decoded !== null) {
                    setInterval(() => {
                        if (Date.now() / 1000 >= decoded.exp && window.location.pathname !== '/login') {
                            //this.setState({ isExpired: true });
                            this.logout();
                        }
                    }, 10000);
                }
            }
            else {
                //this.setState({ isExpired: true });
                //this.logout();
                //authService.logout();
                history.push('/login');
            }
        }));

    }


    render() {
        let { currentUser, loading, isExpired } = this.state;
        return (
            <Router history={history}>
                <Layout>
                    {/*{isExpired &&*/}
                    {/*    //<Route path="*" render={() => {*/}
                    {/*    //    return <Redirect to="/login" />*/}
                    {/*    //}} />*/}
                    {/*    //<div className="clsExpired">*/}
                    {/*    //    <div className="clsBtnExpired">*/}
                    {/*    //        <p>Your session has been expired, please login.</p>*/}
                    {/*    //        <Button color="secondary" onClick={this.logout}>Login</Button>*/}
                    {/*    //    </div>*/}
                    {/*    //</div>*/}
                    {/*}*/}
                    {!loading &&
                        <Switch>
                            {currentUser !== null &&
                                <React.Fragment>
                                    <Route path="/" exact={true} component={Dashboard} />
                                    <Route path="/dashboard" exact={true} component={Dashboard} />
                                    <Route path="/resetpassword" exact={true} component={ResetPassword} />
                                    <Route path="/users" exact={true} component={Users} />
                                    <Route path="/adduser" exact={true} component={AddUser} />
                                    <Route path="/requests" exact={true} component={Requests} />
                                    {/*{isExpired && < Route path="*" render={() => {*/}
                                    {/*    return <Redirect to="/login" />*/}
                                    {/*}} />*/}
                                    {/*}*/}
                                </React.Fragment>
                            }
                            {currentUser === null &&
                                <React.Fragment>
                                    <Route path="/forgotpassword" exact={true} component={ForgotPassword} />
                                    <Route path="/login" exact={true} component={Login} />
                                    <Route path="/resetpassword" exact={true} component={ResetPasswordUser} />
                                    <Route path="/" exact={true} component={Login} />
                                    {/*<Redirect to='/login' />*/}
                                    {/*<Route path="*" render={() => {*/}
                                    {/*    return <Redirect to="/login" />*/}
                                    {/*}} />*/}
                                </React.Fragment>
                            }
                        </Switch>
                    }
                </Layout>
            </Router>
        );
    }
}

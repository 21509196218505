import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { userService } from '../../Shared/Services';
class Users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allUsers: []
        };
    }
    componentDidMount() {
        userService.getAllUsers().then((resp) => {
            if (resp.status === true)
                this.setState({ allUsers: resp.data })
        });
    }

    render() {
        let users = this.state.allUsers;
        return (
            <div className="container mt-2">
                <Link className="btn btn-dark" to='/adduser'>Create new user</Link>
                <br />
                <br />
                <div className="row">
                    <div className="container">
                    <div className="col-12 card">
                        {
                            users.length === 0 ? <p>Loading...</p> :
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>First name</th>
                                            <th>Last name</th>
                                            <th>Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((item) => (
                                            <tr key={item.email}>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{item.email}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                        }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Users;
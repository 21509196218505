import React, { Component } from "react";
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import { colors } from "@atlaskit/theme";
import { userService, ItemService, authService } from "../../Shared/Services";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ItemList from "./Items/ItemList";
import usersIcon from "../../images/users.png";
import Select from 'react-select';
import ContentEditable from 'react-contenteditable'
import Switch from "react-switch";
//import Title from "./title";

const Container = styled.div`
  margin-left: 15px;
  display: inline-flex;
  flex-direction: column;
  width:350px;
`;

const Header = styled.div`
  display: inline;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${({ isDragging }) =>
        isDragging ? colors.G50 : colors.N30};
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${colors.G50};
  }
`;

const Title = styled.h4`
    padding: 8px 12px;
    padding-bottom:0px;
    margin-bottom:0px;
    transition: background-color ease 0.2s;
    flex-grow: 1;
    user-select: none;
    position: relative;
    &:focus {
        outline: 2px solid black;
        outline-offset: 2px;
    }
`;


export default class Column extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listId: 0,
            ListName: '',
            model: false,
            users: [],
            sharedUsers: [],
            selectedUser: { value: 0 },
            selectedOwnerUser: { value: 0 },
            error: "",
            modelAddItem: false,
            modelDeleteList: false,
            fields: {
                Name: '',
                Description: '',
                ConfirmYes: '',
                ConfirmYesCO: ''
            },
            submitted: false,
            errors: {},
            success: "",
            errorAddItem: "",
            loading: false,
            changeOwnerUsers: [],
            currentUser: null,
            //items : props.items
            showCompleted: false
        };
    }
    componentWillMount() {
        authService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }
    //componentWillReceiveProps(nextProps) {
    //    this.setState({ items: nextProps.items });
    //}
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["Name"]) {
            formIsValid = false;
            errors["Name"] = "Please provide item name";
        }
        if (!fields["Description"]) {
            formIsValid = false;
            errors["Description"] = "Please provide description";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    addItemSubmit() {
        let fields = this.state.fields;
        this.setState({ success: "", errorAddItem: "" });
        if (this.handleValidation()) {
            this.setState({ loading: true });
            ItemService.addNewItem({ ListId: this.props.list.listId, Name: fields["Name"], Description: fields["Description"] }).then((resp) => {
                if (resp.status === false) {
                    this.setState({ error: resp.message });
                }
                else {
                    var handleToUpdate = this.props.handleToUpdate;
                    handleToUpdate(resp.data);
                    this.setState({
                        fields: {
                            Name: '',
                            Description: '',
                        }
                    });
                    this.setState({ success: resp.message });
                }
                this.setState({ loading: false });
            });
        } else {
        }
    }

    deleteListSubmit() {
        this.setState({ success: "", errorAddItem: "" });
        if (this.state.fields.ConfirmYes.toLocaleLowerCase() === 'yes') {
            this.setState({ loading: true });
            userService.deleteList({ ListId: this.props.list.listId }).then((resp) => {
                if (resp.status === false) {
                    this.setState({ error: resp.message });
                }
                else {
                    var handleToUpdate = this.props.handleToUpdate;
                    handleToUpdate(resp.data);
                    this.setState({
                        fields: {
                            Name: '',
                            Description: '',
                        }
                    });
                    this.setState({ success: resp.message });
                }
                this.setState({ loading: false });
            });
        } else {
        }
    }
    getUserList() {
        userService.getSharedUsersLists({ ListId: this.props.list.listId }).then((resp) => {
            if (resp.status === true) {
                this.setState({
                    users: resp.data.users,
                    sharedUsers: resp.data.sharedUsers
                });
            }
        })
    }
    toggleModel = () => {
        this.setState({ model: !this.state.model }, () => {
            if (this.state.model === true) {
                this.getUserList();
            }
        })
    };
    toggleDeleteModel = () => {
        this.setState({ modelDeleteList: !this.state.modelDeleteList });
    };
    toggleAddItemModel = () => {
        this.setState({ modelAddItem: !this.state.modelAddItem });
    };
    componentDidMount() {
        this.setState({ ListName: this.props.list.name })
    }
    toggleTitle = (list) => {
        this.setState({
            listId: list.listId
        })
    }
    handleChange(e) {
        var value = e.target.value;
        this.setState({ ListName: value })
    }
    handleItemChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    handleUserChange(e) {
        this.setState({ selectedUser: e })
    }
    handleOwnerUserChange(e) {
        this.setState({ selectedOwnerUser: e })
    }

    handleBlur = () => {
        if (this.state.ListName === "") {
            this.setState({ ListName: "Untitled section" })
        }
        userService.changeListName({ ListId: this.state.listId, ListName: this.state.ListName })
        this.setState({
            listId: ""
        });
    }
    shareListWithUser = () => {
        if (this.state.selectedUser.value === 0) {
            this.setState({ error: "Please select user to share list" })
        }
        else {
            this.setState({ error: "" })
            userService.shareListWithUser({ ListId: this.props.list.listId, UserId: this.state.selectedUser.value }).then((resp) => {
                if (resp.status === true) {
                    this.setState({
                        users: resp.data.users,
                        sharedUsers: resp.data.sharedUsers,
                        selectedUser: { value: 0 }
                    });
                    var handleToUpdate = this.props.handleToUpdate;
                    handleToUpdate(resp.data.userLists);
                }
            })
        }
    }

    toggleChangeOwnerModel = () => {
        this.setState({ modelChangeOwner: !this.state.modelChangeOwner });
        this.getChangeOwnerData();
    };
    getChangeOwnerData = () => {
        userService.getChangeOwnerUsers().then((resp) => {
            if (resp.status === true) {
                this.setState({
                    changeOwnerUsers: resp.data
                });
            }
        })
    }
    changeListOwner = () => {
        let errors = {};
        if (this.state.selectedOwnerUser.value > 0) {
            if (this.state.fields.ConfirmYesCO.toLocaleLowerCase() === 'yes') {
                this.setState({ loading: true, error:"",success:""});
                userService.changeOwner({ ListId: this.props.list.listId, UserId: this.state.selectedOwnerUser.value }).then((resp) => {
                    if (resp.status === true) {
                        var handleToUpdate = this.props.handleToUpdate;
                        handleToUpdate(resp.data);
                        this.setState({ loading: false, success : "Request submitted successfully" })
                    }
                    else {
                        this.setState({ loading: false, error: "This list already has a pending ownership request" })
                    }
                })
            }
            else {
                errors["ConfirmYesCO"] = "Please enter yes to change owner";
                this.setState({ errors: errors, submitted: true });
            }

        }
        else {
            errors["OwnerError"] = "Please select user";
            this.setState({ errors: errors });
        }
    }

    handleSwitchChange = (checked) => {
        this.setState({ showCompleted: checked });
    }
    render() {

        const index = this.props.index;
        const list = this.props.list;
        const items = this.props.items;

        let loading = this.state.loading;
        let error = this.state.error;
        let success = this.state.success;
        let showCompleted = this.state.showCompleted;
        return (
            <div key={list.listId}>
                <Draggable draggableId={list.name + list.listId} index={index} >
                    {(provided, snapshot) => (
                        <Container ref={provided.innerRef} {...provided.draggableProps}>
                            <Header isDragging={snapshot.isDragging}>
                                <Title
                                    isDragging={snapshot.isDragging}
                                    {...provided.dragHandleProps}
                                >
                                    {this.state.listId !== list.listId ?
                                        <div className="dilblock">
                                            <p className="curp pmaxW listName" onClick={() => this.toggleTitle(list)}>{this.state.ListName}

                                            </p>
                                          
                                            <div className="dropdown dbfr">
                                                <span className="lh15">
                                                    <Switch height={20}
                                                        width={40} onChange={this.handleSwitchChange} checked={this.state.showCompleted} />
                                                </span>
                                                <span>{list.shareCount > 0 ? <img src={usersIcon} alt="users" /> : ""}</span>
                                                <button className="btn btn-light btn-sm pt-0 mr-1 ml-1" type="button" onClick={() => this.toggleAddItemModel()}>
                                                    +
                                                </button>
                                                <button className="btn btn-light btn-sm pt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    ...
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <span onClick={() => this.toggleModel()} className="dropdown-item">Share</span>
                                                    {this.state.currentUser.userId === list.userId ? (
                                                        <span onClick={() => this.toggleChangeOwnerModel()} className="dropdown-item">Change Owner</span>
                                                    ) : null}
                                                    <span onClick={() => this.toggleTitle(list)} className="dropdown-item">Rename</span>
                                                    {this.state.currentUser.userId === list.userId ? (
                                                        <span onClick={() => this.toggleDeleteModel(list)} className="dropdown-item">Delete</span>
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div> :
                                        <div><input type="text"
                                            onBlur={() => this.handleBlur()}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    this.handleBlur();
                                                }
                                            }}
                                            onChange={this.handleChange.bind(this)} className="form-control form-control-sm" autoFocus value={this.state.ListName} /></div>
                                    }

                                </Title>
                            </Header>
                            <ItemList
                                handleToUpdate={this.props.handleToUpdate.bind(this)}
                                listId={"List-" + list.listId}
                                listType="List"
                                style={{
                                    backgroundColor: snapshot.isDragging ? 'green' : showCompleted ? colors.G50 : null
                                }}
                                items={showCompleted ? items.filter((a) => a.isCompleted === true).slice(0, 10) : items.filter((a) => a.isCompleted !== true)}
                                showCompleted={showCompleted}
                                internalScroll={true}
                                isCombineEnabled={Boolean(this.props.isCombineEnabled)}
                            />
                        </Container>
                    )}
                </Draggable>
                <div>
                    <Modal toggle={() => this.setState({ modelChangeOwner: !this.state.modelChangeOwner })} isOpen={this.state.modelChangeOwner} >
                        <ModalHeader>Change Owner</ModalHeader>
                        {this.state.changeOwnerUsers &&
                            <ModalBody>
                            {success !== "" && <div className="alert alert-success" role="alert">
                                {success}
                            </div>}
                            {error !== "" && <div className="alert alert-danger" role="alert">
                                {error}
                            </div>}
                                <label>Select new owner</label>
                                <Select value={this.state.selectedOwnerUser} options={this.state.changeOwnerUsers} isSearchable={true} onChange={this.handleOwnerUserChange.bind(this)} />
                                <span className="text-danger">{this.state.errors["OwnerError"]}</span>
                                {this.state.submitted &&
                                    <div className="form-white mb-4 mt-4">
                                        <label className="form-label" htmlFor="ConfirmYesCO">Please type <b>yes</b> to change the owner. You will no longer have access to this list.</label>
                                        <input type="text" id="ConfirmYesCO" name="ConfirmYesCO" className="form-control form-control-lg"
                                            onChange={this.handleItemChange.bind(this, "ConfirmYesCO")}
                                            value={this.state.fields["ConfirmYesCO"]}
                                        />
                                        <span className="text-danger">{this.state.errors["ConfirmYesCO"]}</span>
                                    </div>
                                }
                            </ModalBody>
                        }
                        <ModalFooter>
                            {loading ?
                                <Button color="secondary" disabled >
                                    <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span></Button> :
                                <Button color="success" type="button" onClick={() => this.changeListOwner()}>Change Owner</Button>
                            }
                            {' '}
                            <Button color="secondary" onClick={() => this.setState({ modelChangeOwner: !this.state.modelChangeOwner })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal toggle={() => this.setState({ model: !this.state.model })} isOpen={this.state.model} >
                        <ModalHeader>{list.name}</ModalHeader>
                        {this.state.users &&
                            <ModalBody>
                                <label>Select user</label>
                                <Select value={this.state.selectedUser} options={this.state.users} isSearchable={true} onChange={this.handleUserChange.bind(this)} />
                                <span className="text-danger">{this.state.error}</span>
                                <br />
                                <label className="bold-text">Shared with users</label>
                                {this.state.sharedUsers.length > 0 && this.state.sharedUsers.map((user, index) => (
                                    <div key={index} >{user.firstName + " " + user.lastName}</div>
                                ))
                                }
                            </ModalBody>
                        }
                        <ModalFooter>
                            <Button color="primary" onClick={() => this.shareListWithUser()}>Share</Button>{' '}
                            <Button color="secondary" onClick={() => this.setState({ model: !this.state.model })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal toggle={() => this.setState({ modelAddItem: !this.state.modelAddItem })} isOpen={this.state.modelAddItem} >
                        <ModalHeader>Add new item</ModalHeader>
                        <ModalBody>
                            {success !== "" && <div className="alert alert-success" role="alert">
                                {success}
                            </div>}
                            {error !== "" && <div className="alert alert-danger" role="alert">
                                {error}
                            </div>}
                            <div className="form-white mb-4">
                                <label className="form-label" htmlFor="Name">Name</label>
                                <input type="text" id="Name" name="Name" className="form-control form-control-lg"
                                    onChange={this.handleItemChange.bind(this, "Name")}
                                    value={this.state.fields["Name"]}
                                />
                                <span className="text-danger">{this.state.errors["Name"]}</span>
                            </div>
                            <div className="form-white mb-4">
                                <label className="form-label" htmlFor="Description">Description</label>
                                <ContentEditable
                                    id="Description" name="Description" className="minh100 form-control"
                                    html={this.state.fields["Description"]} // innerHTML of the editable div
                                    disabled={false}       // use true to disable editing
                                    onChange={this.handleItemChange.bind(this, "Description")}
                                />
                                <span className="text-danger">{this.state.errors["Description"]}</span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {loading ?
                                <Button color="secondary" disabled >
                                    <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span></Button> :
                                <Button color="success" type="button" onClick={() => this.addItemSubmit()}>Create item</Button>
                            }
                            <Button color="secondary" onClick={() => this.setState({ modelAddItem: !this.state.modelAddItem })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    <Modal toggle={() => this.setState({ modelDeleteList: !this.state.modelDeleteList })} isOpen={this.state.modelDeleteList} >
                        <ModalHeader>Delete list</ModalHeader>
                        <ModalBody>
                            <div className="form-white mb-4">
                                <label className="form-label" htmlFor="ConfirmYes">Please type <b>yes</b> to confirm delete</label>
                                <input type="text" id="ConfirmYes" name="ConfirmYes" className="form-control form-control-lg"
                                    onChange={this.handleItemChange.bind(this, "ConfirmYes")}
                                    value={this.state.fields["ConfirmYes"]}
                                />
                                <span className="text-danger">{this.state.errors["ConfirmYes"]}</span>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {loading ?
                                <Button color="secondary" disabled >
                                    <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span></Button> :
                                <Button color="success" type="button" onClick={() => this.deleteListSubmit()}>Delete list</Button>
                            }
                            <Button color="secondary" onClick={() => this.setState({ modelDeleteList: !this.state.modelDeleteList })}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

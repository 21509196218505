import React from "react";
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import Moment from 'moment';

const getBackgroundColor = (isDragging, isGroupedOver) => {
    if (isDragging) {
        return colors.N30;
    }

    if (isGroupedOver) {
        return colors.N30;
    }

    return colors.N0;
};

const getBorderColor = (isDragging) =>
    isDragging ? colors.green : "transparent";

const Container = styled.a`
  border-radius: 10px;
  border: 2px solid transparent;
  border-color: ${props => getBorderColor(props.isDragging)};
  background-color: ${props =>
        getBackgroundColor(props.isDragging, props.isGroupedOver)};
  box-shadow: ${({ isDragging }) =>
        isDragging ? `2px 2px 1px ${colors.N70}` : "none"};
  padding: 8px;
  min-height: 40px;
  margin-bottom: 5px;
  user-select: none;

  /* anchor overrides */
  color: ${colors.N900};

  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

//const Avatar = styled.img`
//  width: 40px;
//  height: 40px;
//  border-radius: 50%;
//  margin-right: 10px;
//  flex-shrink: 0;
//  flex-grow: 0;
//`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;

//const BlockQuote = styled.div`
//  &::before {
//    content: open-quote;
//  }
//  &::after {
//    content: close-quote;
//  }
//`;

const Footer = styled.div`
  font-style: italic;
  margin-top: 3px;
  align-items: center;
`;

const Author = styled.small`
  margin: 0;
  font-weight: normal;
`;

const QuoteId = styled.small`
  margin: 0;
  font-weight: normal;
`;

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
export default class Item extends React.PureComponent {
    handleEdit = () => {
        var handleEdit = this.props.handleEdit;
        handleEdit(this.props.item);
    }
    render() {
        const { item, isDragging, isGroupedOver, provided, showCompleted } = this.props;
        return (
            <Container
                onClick={this.handleEdit}
                isDragging={isDragging}
                isGroupedOver={isGroupedOver}

                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {/*<Avatar src={quote.author.avatarUrl} alt={quote.author.name} />*/}
                <Content>
                    <span className="bold-text">{item.name}</span>
                    <span className="descriptionText" dangerouslySetInnerHTML={{ __html: item.description.replace(/<[^>]+>/g, '').length > 150 ? item.description.replace(/<[^>]+>/g, '').substring(0, 150) + "..." : item.description }}></span>
                    {showCompleted && < Footer >
                        <Author>Completed By: {item.completedByUser.firstName + " " + item.completedByUser.lastName}</Author>
                        <br />
                        <QuoteId>Completed On: {Moment(item.completedOn).format('MM/DD/YYYY')}</QuoteId>
                    </Footer>
                    }
                </Content>
            </Container>
        );
    }
}

import React from "react";
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Item from "./item";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ItemService } from "../../../Shared/Services";
import ContentEditable from 'react-contenteditable'
import trashIcon from "../../../images/trash.png";

const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) {
        return colors.R50;
    }
    if (isDraggingFrom) {
        return colors.T50;
    }
    return colors.N30;
};
const Title = styled.h4`
    padding: 8px;
    padding-bottom:0px;
    margin-bottom:0px;
    transition: background-color ease 0.2s;
    flex-grow: 1;
    user-select: none;
    position: relative;
    &:focus {
        outline: 2px solid black;
        outline-offset: 2px;
    }
`;


const Wrapper = styled.div`
  background-color: ${props =>
        getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: 10px;
  border: 1px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
`;

const scrollContainerHeight = 250;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  //min-height: ${scrollContainerHeight}px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: 10px;
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  //max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

class InnerItemList extends React.Component {
    //shouldComponentUpdate(nextProps) {
    //    if (nextProps.items !== this.props.items) {
    //        return true;
    //    }
    //    return false;
    //}
    constructor(props) {
        super(props);
        this.state = {
            modelEditItem: false,
            loading: false,
            fields: {
                Name: '',
                Description: '',
                ItemId: ''
            },
            errors: {},
        }
        //var handleEdit = this.handleEdit.bind(this);
    }
    EditModelOpen = (item) => {
        this.setState({
            modelEditItem: true
        }, () => {
            console.log(item);
        })
    }
    handleEdit(item) {
        /*if (!this.props.showCompleted) {*/
            let fields = this.state.fields;
            fields.Name = item.name;
            fields.Description = item.description;
            fields.ItemId = item.itemId;
            this.setState({ modelEditItem: true })
        //}
    }
    handleItemChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["Name"]) {
            formIsValid = false;
            errors["Name"] = "Please provide item name";
        }
        if (!fields["Description"]) {
            formIsValid = false;
            errors["Description"] = "Please provide description";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    editItemSubmit() {
        let fields = this.state.fields;
        if (this.handleValidation()) {
            this.setState({ loading: true });
            ItemService.editItem(fields).then((resp) => {
                this.setState({ loading: false, modelEditItem: false });
                var handleToUpdate = this.props.handleToUpdate;
                handleToUpdate(resp.data);
            });
        } else {
        }
    }

    deleteItemSubmit() {
        if (window.confirm('Are you sure you want to delete this item?')) {
            let fields = this.state.fields;
            this.setState({ loading: true });
            ItemService.deleteItem({ ItemId: fields.ItemId }).then((resp) => {
                this.setState({ loading: false, modelEditItem: false });
                var handleToUpdate = this.props.handleToUpdate;
                handleToUpdate(resp.data);
            });
        }
    }
    completeItemSubmit() {
        if (window.confirm('Are you sure you want to complete this item?')) {
            let fields = this.state.fields;
            this.setState({ loading: true });
            ItemService.completeItem({ ItemId: fields.ItemId }).then((resp) => {
                this.setState({ loading: false, modelEditItem: false });
                var handleToUpdate = this.props.handleToUpdate;
                handleToUpdate(resp.data);
            });
        }
    }

        
        render() {
            let loading = this.state.loading;
            let showCompleted = this.props.showCompleted;
            return (
                <div>
                    {this.props.items.map((item, index) => (
                        <Draggable
                            key={item.itemId}
                            draggableId={"Item-" + item.itemId}
                            index={index}

                            shouldRespectForceTouch={false}>
                            {(dragProvided, dragSnapshot) => (
                                <Item
                                    handleEdit={this.handleEdit.bind(this)}
                                    key={item.itemId}
                                    item={item}
                                    isDragging={dragSnapshot.isDragging}
                                    isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                                    showCompleted={showCompleted}
                                    provided={dragProvided} />
                            )}
                        </Draggable>
                    ))
                    }
                    <div>
                        <Modal toggle={() => this.setState({ modelEditItem: !this.state.modelEditItem })} isOpen={this.state.modelEditItem} >
                            <ModalHeader>Edit item
                                {!showCompleted &&
                                    <span className="float-right curp" onClick={() => this.deleteItemSubmit()}><img src={trashIcon} alt="delete" /></span>
                                }
                            </ModalHeader>
                            
                            <ModalBody>
                                <div className="form-white mb-2">
                                    <label className="form-label" htmlFor="Name">Name</label>
                                    <input type="text" id="Name" name="Name" className="form-control"
                                        onChange={this.handleItemChange.bind(this, "Name")}
                                        value={this.state.fields["Name"]}
                                    />
                                    <span className="text-danger">{this.state.errors["Name"]}</span>
                                </div>
                                <div className="form-white mb-2">
                                    <label className="form-label" htmlFor="Description">Description</label>
                                    <ContentEditable
                                        id="Description" name="Description" className="minh100 form-control"
                                        html={this.state.fields["Description"]}
                                        disabled={false}
                                        onChange={this.handleItemChange.bind(this, "Description")}
                                    />
                                    <span className="text-danger">{this.state.errors["Description"]}</span>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {loading ?
                                    <Button color="secondary" disabled >
                                        <span className="spinner-border spinner-border" role="status" aria-hidden="true"></span></Button> :
                                    <React.Fragment>
                                        {!showCompleted && <Button color="warning" type="button" onClick={() => this.completeItemSubmit()}>Completed</Button>}
                                        <Button color="success" type="button" onClick={() => this.editItemSubmit()}>Update</Button>
                                    </React.Fragment>
                                }
                                <Button color="secondary" onClick={() => this.setState({ modelEditItem: !this.state.modelEditItem })}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            )
        }
    }

class InnerList extends React.Component {
    render() {

        const { items, dropProvided, showCompleted } = this.props;
        const title = this.props.title ? <Title>{this.props.title}</Title> : null;

        return (
            <Container> 
                {title}
                <DropZone ref={dropProvided.innerRef} className="itemListHeight">
                    <InnerItemList handleToUpdate={this.props.handleToUpdate.bind(this)} items={items} showCompleted={showCompleted} />
                    {dropProvided.placeholder}
                </DropZone>
            </Container>
        );
    }
}

export default class ItemList extends React.Component {
    static defaultProps = {
        listId: "LIST"
    };
    render() {
        const {
            ignoreContainerClipping,
            internalScroll,
            scrollContainerStyle,
            isDropDisabled,
            isCombineEnabled,
            listId,
            listType,
            style,
            items,
            title,
            showCompleted
        } = this.props;


        return (
            <Droppable
                droppableId={listId}
                type={listType}
                ignoreContainerClipping={ignoreContainerClipping}
              
                isCombineEnabled={isCombineEnabled}
            >
                {(dropProvided, dropSnapshot) => (
                    <Wrapper
                        style={style}
                        isDraggingOver={dropSnapshot.isDraggingOver}
                        isDropDisabled={isDropDisabled}
                        isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
                        {...dropProvided.droppableProps}
                    >
                        {internalScroll ? (
                            <ScrollContainer style={scrollContainerStyle} className="itemListHeight">
                                <InnerList
                                    handleToUpdate={this.props.handleToUpdate.bind(this)}
                                    items={items}
                                    title={title}
                                    dropProvided={dropProvided}
                                    showCompleted={showCompleted}
                                />
                            </ScrollContainer>
                        ) : (
                            <InnerList
                                    handleToUpdate={this.props.handleToUpdate.bind(this)}
                                    items={items}
                                    title={title}
                                    dropProvided={dropProvided}
                                    showCompleted={showCompleted}
                            />
                        )}
                    </Wrapper>
                )}
            </Droppable>
        );
    }
}

import { handleResponse, authHeader } from '../Helpers';

export const userService = {
    addUser, getAllUsers, getUserLists, reorderUserList, changeListName, getSharedUsersLists, shareListWithUser, addNewList, deleteList,
    getChangeOwnerUsers, changeOwner,getOwnerRequests,respOwnerRequest,getPendingRequests
};

function addUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/add`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function getAllUsers() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`/user/all`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function getUserLists() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`/user/lists`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function reorderUserList(listIds) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(listIds)
    };

    return fetch(`/user/lists/reorder`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function changeListName(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/list/rename`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function getSharedUsersLists(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/list/getshared`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function shareListWithUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/list/share`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function addNewList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/addlist`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function deleteList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/list/delete`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function getChangeOwnerUsers() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`/user/getchangeowner`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}

function changeOwner(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/changeownerrequest`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
function getOwnerRequests() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`/user/ownerrequests`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
} 
function respOwnerRequest(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/user/requestresp`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
function getPendingRequests() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`/user/requests/pending`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            return resp;
        });
}
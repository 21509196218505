import React, { Component } from 'react';
 import { Link } from 'react-router-dom';
import { authService } from '../../Shared/Services';
import { history } from '../../Shared/Helpers';
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                email: '',
                password: ''
            },
            errors: {},
            loading: false,
            error: ""
        };
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter email address";
        }

        else if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Email address is not valid";
            }
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please enter password";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    loginSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ loading: true });
            authService.login(this.state.fields.email, this.state.fields.password, false).then((resp) => {
                if (resp.status === false) {
                    this.setState({ loading: false });
                    this.setState({ error: "Username or Password is not valid" });
                }
                else {
                    history.push('/dashboard');
                }
            });
        } else {
            // alert("Form has errors.");
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    render() {
        let loading = this.state.loading;
        let error = this.state.error;
        return (
            <section className="">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card mt-4">
                                <div className="card-body p-md-5">
                                    <div className="mb-md-2 mt-md-2 pb-0">
                                        <h2 className="fw-bold mb-2 text-center">Login</h2>
                                        <p className="text-white-50 mb-3 text-center">Please enter your login and password!</p>
                                        {error !== "" && <div className="alert alert-danger" role="alert">
                                            {error}
                                        </div>}
                                        <form name="loginForm" onSubmit={this.loginSubmit.bind(this)}        >
                                            <div className="form-white mb-4">
                                                <label className="form-label" htmlFor="email">Email</label>
                                                <input type="email" id="email" name="email" className="form-control form-control-lg"
                                                    onChange={this.handleChange.bind(this, "email")}
                                                    value={this.state.fields["email"]}
                                                />
                                                <span className="text-danger">{this.state.errors["email"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <label className="form-label" htmlFor="password">Password</label>
                                                <input type="password" id="password" name="password" className="form-control form-control-lg"
                                                    onChange={this.handleChange.bind(this, "password")}
                                                    value={this.state.fields["password"]}
                                                />
                                                <span className="text-danger">{this.state.errors["password"]}</span>
                                            </div>

                                            <p className="small mb-2 pb-lg-2 text-center">
                                                 <Link to="/forgotpassword">Forgot password?</Link>
                                            <br />
                                            <br /> 
                                                {
                                                    loading ? <button className="btn btn-primary btn-lg px-5" disabled type="button">
                                                        <span className="spinner-border spinner-border" role="status"></span>
                                                    </button> :
                                                        <button className="btn btn-primary btn-lg px-5" type="submit">Login</button>
                                                }
                                            </p>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Login;
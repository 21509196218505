import React, { Component } from 'react';
import  {authService}  from '../../Shared/Services';
class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                Password:'',
                NewPassword:'',
                ConfirmPassword:''
            },
            errors: {},
        };
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (!fields["Password"]) {
            formIsValid = false;
            errors["Password"] = "Please provide current password";
        }

        if (!fields["NewPassword"]) {
            formIsValid = false;
            errors["NewPassword"] = "Please provide new password";
        }
        if (!fields["ConfirmPassword"]) {
            formIsValid = false;
            errors["ConfirmPassword"] = "Please provide confirm password";
        }

        else if(fields["NewPassword"] !== fields["ConfirmPassword"]){
            formIsValid = false;
            errors["ConfirmPassword"] = "Password does not match";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    resetPasswordSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            authService.resetpassword(this.state.fields).then((resp) => {
                if(resp.status === false){
                  alert(resp.message);
                }
                else{
                    this.setState({fields: {
                        Password:'',
                        NewPassword:'',
                        ConfirmPassword:''
                    } });
                    alert(resp.message);
                }
            });
        } else {
            // alert("Form has errors.");
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    render() {
        return (
            <section className="">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body p-md-5">

                                    <div className="mb-md-2 mt-md-2 pb-0">

                                        <h2 className="fw-bold mb-2 text-center">Reset Password</h2>
                                        <p className="text-white-50 mb-3 text-center">Reset your password!</p>
                                        <form name="loginForm" onSubmit={this.resetPasswordSubmit.bind(this)}        >
                                            <div className="form-white mb-4">
                                                <label className="form-label" htmlFor="Password">Current password</label>
                                                <input type="password" id="Password" name="Password" className="form-control form-control-lg"
                                                    onChange={this.handleChange.bind(this, "Password")}
                                                    value={this.state.fields["Password"]}
                                                />
                                                <span className="text-danger">{this.state.errors["Password"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <label className="form-label" htmlFor="NewPassword">New password</label>
                                                <input type="password" id="NewPassword" name="NewPassword" className="form-control form-control-lg"
                                                onChange={this.handleChange.bind(this, "NewPassword")}
                                                value={this.state.fields["NewPassword"]}
                                                />
                                                <span className="text-danger">{this.state.errors["NewPassword"]}</span>
                                            </div>

                                            <div className=" form-white mb-4">
                                                <label className="form-label" htmlFor="ConfirmPassword">Confirm new password</label>
                                                <input type="password" id="ConfirmPassword" name="ConfirmPassword" className="form-control form-control-lg"
                                                onChange={this.handleChange.bind(this, "ConfirmPassword")}
                                                value={this.state.fields["ConfirmPassword"]}
                                                />
                                                <span className="text-danger">{this.state.errors["ConfirmPassword"]}</span>
                                            </div>

                                            <p className="small mb-2 pb-lg-2 text-center">
                                                <button className="btn btn-primary btn-lg px-5 w-100" type="submit">Reset password</button>
                                            </p>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default ResetPassword;
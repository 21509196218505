import { handleResponse, authHeader } from '../../Shared/Helpers';
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default reorder;


String.prototype.removePrefix = function (prefix) {
    const hasPrefix = this.indexOf(prefix) === 0;
    return hasPrefix ? this.substr(prefix.length) : this.toString();
};

export const reorderQuoteMap = ({ itemsMap, source, destination, draggableId }) => {

    const srclistId = source.droppableId.removePrefix("List-");
    var sourceList = itemsMap.find(obj => obj.listId === +srclistId);
    var sourceIndex = itemsMap.indexOf(sourceList);

    const deslistId = destination.droppableId.removePrefix("List-");
    var destinationList = itemsMap.find(obj => obj.listId === +deslistId);
    var destinationIndex = itemsMap.indexOf(destinationList);

    const srcItemId = draggableId.removePrefix("Item-");
    const Item = itemsMap[sourceIndex].items.find(aa => aa.itemId === +srcItemId)
    var ItemIndex = itemsMap[sourceIndex].items.indexOf(Item);
    Item.listId = +deslistId;
    
    //remove from source
    itemsMap[sourceIndex].items.splice(ItemIndex, 1);
    //add to new list
    itemsMap[destinationIndex].items.splice(destination.index, 0, Item);
    //console.log(itemsMap[destinationIndex].items);
    ChangeItemsList({ ItemId: srcItemId, DestinationListId: deslistId, SourceListId: srclistId, Items: itemsMap[destinationIndex].items });
    return [
        ...itemsMap
    ];
};

function ChangeItemsList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`/item/reorder`, requestOptions)
        .then(handleResponse)
        .then(resp => {
            //console.log(resp);
            //return resp;
        });
}

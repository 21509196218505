import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { authService } from '../Shared/Services';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null
        };
    }
    componentDidMount() {
        authService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }
    render() {
        let { currentUser } = this.state;
        return (
            <div>
                {currentUser !== null &&
                    <NavMenu />
                }
                {this.props.children}
            </div>
        );
    }
}
